import React, { useEffect } from 'react'
import useMobileDetect from '../hooks/mobileHook';

const DemoChatbot = () => {
  const isMobile = useMobileDetect()

  useEffect(() => {
    // Initialize the script logic here
    const chatbotSrc = 'https://kwixi.ai/client/cus_test/';
    const chatbotOrigin = new URL(chatbotSrc).origin;

    const isMobileDevice = () => window.innerWidth <= 768;
    window.isMobile = isMobileDevice();

    function sendMobileStatusToIframe() {
      const iframe = document.getElementById('demo-chatbot-iframe');
      console.log('Sending mobile status to iframe: isMobile =', window.isMobile);
      iframe.contentWindow.postMessage({ action: 'isMobileResponse', isMobile: window.isMobile }, '*');
    }

    const toggleOverflow = (hidden = false) => {
      const value = hidden ? 'hidden' : 'auto';
      document.documentElement.style.overflow = value;
      document.body.style.overflow = value;
    };

    const resizeChatbot = (container, width, height) => {
      container.style.width = width;
      container.style.height = height;
    };

    const setChatbotPosition = (zIndex, bottom, mobile_bottom) => {
      const container = document.getElementById('demo-chatbot-container');
      container.style.zIndex = zIndex;
      if (mobile_bottom !== undefined) {
        container.dataset.mobile_bottom = mobile_bottom;
      }
      container.style.bottom = `${window.isMobile ? container.dataset.mobile_bottom || bottom : bottom}px`;
    };

    const openOrCloseChat = (action) => {
      const iframe = document.getElementById('demo-chatbot-iframe');
      iframe.contentWindow.postMessage({ action }, '*');
    };

    const initializeIframe = () => {
      console.log('Initializing chatbot...');
      const iframe = document.getElementById('demo-chatbot-iframe');
      if (iframe) {
        iframe.src = chatbotSrc;
        iframe.addEventListener('load', () => {
          minimizeChatbot();
          setTimeout(sendMobileStatusToIframe, 100);
        });
      }
    };

    function sendDataToChatbot(data) {
      const iframe = document.getElementById('demo-chatbot-iframe');
      iframe.contentWindow.postMessage(data, '*');
    }

    let minimizeTimeoutId;
    const minimizeChatbot = () => {
      const container = document.getElementById('demo-chatbot-container');
      container.style.bottom = window.isMobile && container.dataset.mobile_bottom ? `${container.dataset.mobile_bottom}px` : '0px';
      minimizeTimeoutId = setTimeout(() => resizeChatbot(container, '82px', '82px'), 400);
      toggleOverflow();
    };

    const maximizeChatbot = (isMobile) => {
      clearTimeout(minimizeTimeoutId);
      const container = document.getElementById('demo-chatbot-container');
      resizeChatbot(container, isMobile ? '100%' : '380px', isMobile ? '100%' : '680px');
      container.style.bottom = '0px';
      toggleOverflow(isMobile);
    };

    const handleMessageEvent = (event) => {
      const { action, state, zIndex, bottom, mobile_bottom, url } = event.data;
      const container = document.getElementById('demo-chatbot-container');
      switch (action) {
        case 'resize':
          if (state === 'closed') minimizeChatbot();
          else if (state === 'popup') resizeChatbot(container, '320px', '190px');
          else if (state === 'open') maximizeChatbot(window.isMobile);
          break;
        case 'setChatbotPosition':
          setChatbotPosition(zIndex, bottom, mobile_bottom);
          break;
        case 'openBookingLink':
          sendDataToChatbot({ action: 'storeMeeting', meetingDetails: '' });
          window.open(url, '_blank', 'noopener,noreferrer');
          break;
        default:
          break;
      }
    };

    window.addEventListener('resize', sendMobileStatusToIframe);
    window.addEventListener('load', initializeIframe);
    window.addEventListener('message', handleMessageEvent);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', sendMobileStatusToIframe);
      window.removeEventListener('load', initializeIframe);
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);


  // useEffect(() => {
  //   let minimizeDemoTimeoutId;
  //   function minimizeChatbot() {
  //     const container = document.getElementById('demo-chatbot-container');
  //     minimizeDemoTimeoutId = setTimeout(() => {
  //       container.style.width = '82px';
  //       container.style.height = '82px';
  //     }, 400);

  //     document.documentElement.style.overflow = 'auto';
  //     document.body.style.overflow = 'auto';
  //   }
  //   function maximizeChatbot(isMobile) {
  //     clearTimeout(minimizeDemoTimeoutId);
  //     const container = document.getElementById('demo-chatbot-container');
  //     let newWidth;
  //     let newHeight;
  //     if (isMobile) {     
  //       newHeight = '100%';
  //       newWidth = '100%';
  //     } else {
  //       newHeight = '680px';
  //       newWidth = '380px';
  //     }
  //     container.style.width = newWidth;
  //     container.offsetWidth;
  //     container.style.height = newHeight;
  //   }
  //   function handleResize(event) {
  //     if (event.origin !== "https://kwixi.ai") return;
  //     var container = document.getElementById('demo-chatbot-container');
  //     const state = event.data.state;
  //     const action = event.data.action;
  //     if (action === 'resize' && state) {
  //       if (state === 'closed') {
  //         minimizeChatbot()
  //       } else if (state === 'popup') {
  //         container.style.width = '320px'
  //         container.style.height = '190px'
  //         document.documentElement.style.overflow = 'auto';
  //         document.body.style.overflow = 'auto'
  //       } else if (state === 'open') {
  //         maximizeChatbot(isMobile)
  //         if (isMobile) {
  //           document.documentElement.style.setProperty('overflow', 'hidden', 'important');
  //           document.body.style.setProperty('overflow', 'hidden', 'important');
  //         } else {
  //           document.documentElement.style.overflow = 'auto';
  //           document.body.style.overflow = 'auto'
  //         }
  //       }
  //     } else if (action === 'setBottomPosition') {
  //       container.style.bottom = `${event.data.bottom}px`
  //     } else if (action === 'setZIndex') {
  //       container.style.zIndex = `${event.data.zIndex}`;
  //     }
  //   }


  //   window.addEventListener('message', handleResize);

  //   return () => {
  //     window.removeEventListener('message', handleResize);
  //   };
  // }, []);

  return (
    <div id="demo-chatbot-container"
      style={{
      position: 'fixed',
      right: '0px',
      bottom: '0',
      zIndex: 1000,
      width: '76px',
      height: '76px',
      // transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
      overflow: 'hidden',
      border: 'none'
    }}>
      <iframe id="demo-chatbot-iframe"
          // src="http://localhost:3004/client/cus_test/"
          // src='https://kwixi.ai/client/AXTRD9142'
          src='https://kwixi.ai/client/cus_test'
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
            overscrollBehavior: 'none'
          }}>
      </iframe>
    </div>
  )
}

export default DemoChatbot