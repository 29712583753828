import React from 'react'

import { IoIosCheckmarkCircle } from "react-icons/io";

const PricingCard = ({ children }) => {
  return (
    <div className='border border-[#281a39] rounded-lg font-medium p-4 shadow-3xl w-[330px] flex flex-col gap-4 text-left'>
      {children}
    </div>
  )
}


const PlansPage = () => {
  return (
    <div className='flex justify-center pb-16 pt-6'>
      <div>
        <div className='sm:mb-16 mb-8 flex flex-col'>
          <h1 className='sm:text-[72px] text-[48px] font-semibold '>Our pricing plans</h1>
          <h2 className='font-medium text-[24px] line-height text-center sm:ml-40 ml-12'>Find one that works for you</h2>
        </div>
          

          <div className='flex sm:flex-row flex-col items-center sm:justify-center sm:gap-6 gap-6'>
            <PricingCard>
              <div className='border-b border-[#281a39] py-4 sm:h-[320px]  flex flex-col justify-between gap-6'>
                <p className=''>Small Business</p>
                <p className=' text-[32px]'>Let's Chat</p>
                <p className='font-thin' >Qualify for small business pricing</p>

                <button className='bg-[#77E4E7] text-[#FEFDFB] rounded-full py-2 '>Get More Details</button>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>AI Chatbot</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Lead Generator</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Advanced Analytics</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>SEO Optimization</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#e6e6e6] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Schedule Meetings</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#e6e6e6] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Multiple Websites</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#e6e6e6] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Additional Customization</p>
                </div>
              </div>
            </PricingCard>

            <PricingCard>
              <div className='border-b border-[#281a39] py-4 sm:h-[320px] justify-between flex flex-col gap-6'>
                <p className=''>Premium</p>
                <p className=' text-[32px]'>Custom <span className='text-[14px]'>/month</span></p>
                <p className='font-thin'>Engage your audiences with advanced<br/>conversational AI, lead conversion, and<br/>
                schedule meetings</p>

                <button className='bg-[#77E4E7] text-[#FEFDFB] rounded-full py-2 '>Get More Details</button>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>AI Chatbot</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Lead Generator</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Advanced Analytics</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>SEO Optimization</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Schedule Meetings</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#e6e6e6] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Multiple Websites</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#e6e6e6] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Additional Customization</p>
                </div>
              </div>


            </PricingCard>

            <PricingCard>
              <div className='border-b border-[#281a39] py-4 sm:h-[320px] justify-between flex flex-col gap-6'>
                <p className=''>Enterprise</p>
                <p className='text-[32px]'>Custom</p>
                <p className='font-thin'>Premium plan for large scale businesses. With high usage, additional customization</p>

                <button className='bg-[#77E4E7] text-[#FEFDFB] rounded-full py-2 '>Get More Details</button>
              </div>

              <div className='flex flex-col gap-2'>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>AI Chatbot</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Lead Generator</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Advanced Analytics</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>SEO Optimization</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Schedule Meetings</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Multiple Websites</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <div className='text-[#4223AD] text-lg rounded-full'><IoIosCheckmarkCircle /></div>
                  <p>Additional Customization</p>
                </div>
              </div>


            </PricingCard>

          </div>
        </div>
      

      
    </div>
  )
}

export default PlansPage