import React from 'react'

const LoadingSpinner = () => {
  const spinnerStyle = {
    width: '58px', 
    height: '58px',
    border: '8px solid rgba(0, 0, 0, 0.1)',
    borderTopColor: '#BBACE9',
    borderLeftColor: '#BBACE9',
    borderBottomColor: '#BBACE9',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div style={spinnerStyle}></div>
      <style>
        {`
          @keyframes spin {
            to { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  )
}

export default LoadingSpinner