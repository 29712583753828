const handleOpenChatbot = () => {
  const iframe = document.getElementById('chatbot-iframe')
  if (iframe) {
    
    iframe.contentWindow.postMessage({ action: 'openChat' }, '*')
    console.log('Open Chat && message posted')
  }
}

export default handleOpenChatbot

