import React from 'react'
import puzzle from '../assets/images/puzzle.png'
import alerts from '../assets/images/alerts.png'
import users from '../assets/images/users.png'
import pencil from '../assets/images/pencil.png'

const CardContainer = ({ children }) => {
  return (
    <div className='sm:w-60 w-full sm:h-[360px] h-[380px]  sm:p-0 p-2 flex justify-center'>
      <div className='flex flex-col w-full sm:w-full items-center p-4 gap-2 shadow-3xl rounded-lg h-full'>
        {children}
      </div>
    </div>
  )
}

export const BusinessSavvyCard = () => {
  return (
    <CardContainer>
      <div className='size-24 rounded-full'>
        <img className='' src={puzzle} />
      </div>
      <h3 className='font-medium text-[20px] tracking-wider mb-4'>Business-Savvy<br/>Learning</h3>
      <p className='font-medium'>Ready to answer any<br/>questions about your<br/>business; industry info,<br/>
      products, services, FAQs.</p>
    </CardContainer>
  )
}

export const SupportCard = () => {
  return (
      <CardContainer>
        <div className='size-24 rounded-full'>
          <img className='' src={users} />
        </div>

        <h3 className='font-medium text-[20px] tracking-wider mb-4 text-pretty'>Optimize<br/>Support</h3>
        <p className='font-medium'>Tailord customer service<br/>designed to meet your<br/>unique business needs.<br/>Fast, powerful and <br/>accurate</p>
      </CardContainer>
  )
}

export const SchedulingCard = () => {
  return (
      <CardContainer>
        <div className='size-24 rounded-full'>
          <img className='' src={pencil} />
        </div>

        <h3 className='font-medium text-[20px] tracking-wider mb-4 text-pretty'>Streamlined<br/>Leads/Scheduling</h3>
        <p className='font-medium'>Engage visitors, obtain<br/>information, and integrate<br/>with your CRM. Set<br/>appointments seamlessly to<br/>your calendar.</p>
      </CardContainer>
  )
}

export const SalesCard = () => {
  return (
      <CardContainer>
        <div className='size-24 rounded-full'>
          <img className='' src={alerts} />
        </div>

        <h3 className='font-medium text-[20px] tracking-wider mb-4 text-pretty'>Boost Sales<br/>Growth</h3>
        <p className='font-medium'>Custom chatbots enhance<br/>lead capture, expanding<br/>your sales pipeline and<br/>allowing your team to focus<br/>on other critical tasks.</p>
      </CardContainer>
  )

}