import React, { useEffect } from 'react'
import useMobileDetect from '../hooks/mobileHook'

const ContactPage = () => {
  return (
    <div className='bg-[#d0f7e2] sm:pt-8 h-full w-full flex flex-col items-center'>
      <h2 className='sm:text-4xl text-lg font-semibold sm:flex hidden text-center'>Schedule a Meeting with Kwixi</h2>

      <CalendlyEmbed />
    </div>
  )
}

export default ContactPage


export const CalendlyEmbed = () => {
  const isMobile = useMobileDetect();
  const desktopStyle = {
    minWidth: '1000px',
    width: '60%',
    height: '700px'
  };

  const mobileStyle = {
    minWidth: '100%',
    width: '100%',
    height: '600px'
  };
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Initialize the Calendly widget after the script has loaded
    script.onload = () => {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/hello-kwixi/30min', // Replace with your Calendly URL
        parentElement: document.getElementById('calendly-embed'),
        // pageSettings: {
          color: '#70f0a6'
        // }
      });
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="calendly-embed" style={isMobile ? mobileStyle : desktopStyle}></div>
  );
};
