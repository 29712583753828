import React, { useState, useEffect } from 'react'
import useMobileDetect from '../hooks/mobileHook';
import KwixiLogo from '../components/KwixiLogo'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import handleOpenChatbot from '../utils/ChatbotOpened';
import { Hamburger, Close } from '../components/Icons'

import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const isMobile = useMobileDetect()
  return (
    !isMobile ? (
      <div className='h-[92px] fixed top-0 left-0 w-full flex justify-center items-center bg-[#FFFFFF] z-50'>
        <div className='h-full flex justify-center items-center'>
            <div className='w-[40px] h-auto mb-2 z-10'>
              <KwixiLogo />
            </div>

            <div className='border-y border-[#CCCCCC] h-[42px] ml-[-10px] flex items-center'>
              <div className='h-fit flex justify-center items-center ml-5'>
                <NavLink to='/' className='text-[28px] leading-none font-bold mr-8'>Kwixi</NavLink>
              </div>

              <ul className='flex gap-8 text-[11px] tracking-[.2rem] uppercase font-black'>
                {/* <li className=''>
                  <NavLink to='/product' >Product</NavLink>
                </li> */}
                <li className=''>
                <NavLink to='/plans'>Plans</NavLink>
                </li>
                {/* <li className=''>
                <NavLink to='/resources'>Resources</NavLink>
                </li> */}
                <li className=''>
                <NavLink to='/learn'>Learn</NavLink>
                </li>
                <li className=''>
                <NavLink to='/contact'>Contact</NavLink>
                </li>
                <li className='mr-2'>
                <NavLink to='/signin'>Members</NavLink>
                </li>
              </ul>
            </div>

            <div className='flex gap-4 ml-4 justify-center items-center'>
              <div className='text-3xl' role='button'><Link to='/signin'><FaUserCircle /></Link></div>
              <button
                onClick={handleOpenChatbot}
                className='bg-content text-[#FAF7FF] rounded-full w-[120px] hover:bg-slate-300 hover:text-content tracking-wider h-[40px] text-[16px]'>
                Let's chat
              </button>
            </div>
        </div>
      </div>
    ) : (
      <MobileNavbar />
    )
  )
}

export default Navbar


const MobileNavbar = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [shouldOpenChatbot, setShouldOpenChatbot] = useState(false);
  const navigate = useNavigate()

  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setNavMenuOpen((prev) => !prev);
  };

  const handleDemoClick = () => {
    navigate('/contact');
    setNavMenuOpen(false)
  };

  const handleChatClick = () => {
    setNavMenuOpen(false);
    setShouldOpenChatbot(true);
  };

  useEffect(() => {
    if (shouldOpenChatbot) {
      handleOpenChatbot();
      setShouldOpenChatbot(false);
    }
  }, [shouldOpenChatbot]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;

    if (navMenuOpen) {
      htmlElement.classList.add('no-scroll');
      bodyElement.classList.add('no-scroll');
    } else {
      htmlElement.classList.remove('no-scroll');
      bodyElement.classList.remove('no-scroll');
    }

    // Cleanup function to ensure body overflow is reset when component unmounts
    return () => {
      htmlElement.classList.remove('no-scroll');
      bodyElement.classList.remove('no-scroll');
    };
  }, [navMenuOpen]);


  return (
    <div className={`fixed top-0 left-0 w-screen z-50 flex h-[80px] justify-between items-center pr-4 bg-[#F6F6F6] ${navMenuOpen ? 'nav-menu-open' : ''}`}>

      <NavLink onClick={() => setNavMenuOpen(false)} className='flex gap-2 ml-2' to='/'>
        <div className='size-8'><KwixiLogo /></div>
        <div className='text-[30px] font-bold'>Kwixi</div>
      </NavLink>

      <div role='button' onClick={toggleDropdown} className='size-7'>
        {navMenuOpen ? <Close /> : <Hamburger /> }
      </div>
      {navMenuOpen && (
        <div className='flex flex-col justify-between fixed inset-0 top-20 left-0 z-20 w-full  uppercase  bg-[#F6F6F6] overflow-hidden'>
          <ul className='text-left text-[24px] font-semibold text-content mt-4'>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/plans' className='block w-full h-full'>plans</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/learn' className='block w-full h-full'>learn</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/contact' className='block w-full h-full'>contact</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/signin' className='block w-full h-full'>members</NavLink></li>
          </ul>

          <div className='flex flex-col mt-auto mx-4 mb-8 gap-6'>
            <button onClick={handleChatClick} className='rounded-lg w-1/2 bg-content text-base py-4 mx-auto'>Let's Chat</button>
            <button onClick={handleDemoClick} className='rounded-lg w-1/2 bg-kwixi-green py-4 mx-auto'>Book a Demo</button>
          </div>
        </div>
      )}
    </div>
  )
}