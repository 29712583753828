import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../components/LoadingSpinner'
import knockoutRenovation from '../assets/images/Knockout-Renovation.png'
import DemoChatbot from '../components/DemoChatbot'

const demoImageUrl = '../assets/images/Knockout-Renovation.png'

const DemoPage = () => {
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const handleImageLoad = () => {
      setLoaded(true)
    }
    const img = new Image()
    img.src = demoImageUrl
    img.onload = handleImageLoad
    img.onerror = handleImageLoad
  }, [])

  useEffect(() => {
    if (loaded) {
      setLoading(false)
    }
  })

  const openCalendlyModal = (url) => {
    const isMobile = window.innerWidth <= 600;
    updateCalendlyStyles(isMobile);

    const overlay = document.getElementById('demo-calendly-overlay');
    const calendlyEmbed = document.getElementById('demo-calendly-embed');
    overlay.style.display = 'block';
    calendlyEmbed.style.display = 'block';

    window.Calendly.initInlineWidget({
      url: url,
      parentElement: calendlyEmbed,
      resize: true
    });

    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  };

  const closeCalendlyModal = () => {
    document.getElementById('demo-calendly-overlay').style.display = 'none';
    document.getElementById('demo-calendly-embed').style.display = 'none';
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
  };
  function openBookingLink(url) {
    window.open(url, '_blank')
  }

  const isCalendlyEvent = (e) => {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
  };

  useEffect(() => {
    const handleMessage = (event) => {

      // if (event.origin !== "http://localhost:3004") return;
      if (event.origin !== 'https://kwixi.ai')
      // console.log('message', event)
      // console.log('event', event.data)
      if (event.data.width && event.data.height) {
        var container = document.getElementById('chatbot-container');
        var iframe = document.getElementById('chatbot-iframe');

        var currentWidth = container.offsetWidth;
        var currentHeight = container.offsetHeight;
        var newWidth = parseInt(event.data.width);
        var newHeight = parseInt(event.data.height);

        if (newWidth < currentWidth || newHeight < currentHeight) {
          container.style.transition = 'width 0.2s ease-in-out, height 0.2s ease-in-out';
        } else {
          container.style.transition = 'none';
        }

        container.style.width = event.data.width;
        container.style.height = event.data.height;
        iframe.style.width = '100%';
        iframe.style.height = '100%';

        setTimeout(() => {
          container.style.transition = 'width 0.2s ease-in-out, height 0.2s ease-in-out';
        }, 200);
      }
      if (event.data.action === 'openBookingLink') {
        openBookingLink(event.data.url)
      }
      if (event.data.action === 'mobileScroll') {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
      }

      // if (event.data.action === 'openCalendlyModal') {
      //   openCalendlyModal(event.data.url);
      // }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const handleCalendlyEvent = (e) => {
      if (isCalendlyEvent(e) && e.data.event === "calendly.event_scheduled") {
        const iframe = document.getElementById('demo-chatbot-iframe');
        iframe.contentWindow.postMessage({ action: 'storeMeeting', meetingDetails: e.data.payload }, 'https://kwixi.ai');
      }
    };

    window.addEventListener('message', handleCalendlyEvent);

    return () => {
      window.removeEventListener('message', handleCalendlyEvent);
    };
  }, []);

  if (loading) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <LoadingSpinner />
      </div>
    );
  }


  return (
    <div style={{ marginTop: '0px', height: 'calc(100vh - 120px)',  overflowY: 'none', }} className='w-full flex justify-center' >
    <div style={{ 
      // width: '100%', 
      // minWidth: '1800px',
      // maxWidth: '2000px',
      overflowY: 'none',
      overflowX: 'none',
      width: '100%',
      height: '100%', 
      // height: 'calc(100vh - 120px)',
      backgroundImage: 'url(/gleamery_background.png)',
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center', 
      position: 'relative',
      }}>
        {/* <KnockoutNavbar /> */}


       
      {/* <div id="calendly-overlay" style={{ display: 'none', position: 'fixed', top: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}></div>
        <div id="calendly-embed" style={{ display: 'none', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'transparent', zIndex: 10000, borderRadius: '8px' }}>
          <div role="button" id="calendly-close" style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', fontSize: '28px', color: '#ff0000', borderRadius: '50%', width: '36px', height: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1001 }} onClick={closeCalendlyModal}>&times;</div>
        </div> */}

      {/* <div id="chatbot-container" style={{ position: 'absolute', right: '12px', bottom: '6px', zIndex: 1000, width: '76px', height: '76px', transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out', overflow: 'hidden', border: 'none' }}>
        <iframe id="chatbot-iframe" src={`${import.meta.env.VITE_DEMO_CHATBOT_URL}`} style={{ width: '100%', height: '100%', border: 'none', overflow: 'hidden', overscrollBehavior: 'none' }}>
        </iframe>
      </div> */}
      <DemoChatbot />
  </div>
  </div>
  )
}

export default DemoPage

const KnockoutNavbar = () => {
  return (
    <div className='w-screen flex justify-center'>
      <div className='h-12 ml-6 mt-4 flex gap-32'>
        <img className='h-20' src={knockoutRenovation}  />
        {/* <div className='flex'> */}
        <ul className='uppercase flex items-center gap-8 whitespace-nowrap font-medium mt-16'>
          <li className='text-red-500 border-y-2 border-red-500 py-6 px-6'>home</li>
          <li>full home remodeling</li>
          <li>our process</li>
          <li>quality brands</li>
          <li>portfolio</li>
          <li>offers</li>
          <li>about</li>
          <li>contact</li>
        </ul>

        <div className='flex flex-col whitespace-nowrap'>
          <p className='text-red-500 font-bold text-xl'>(212) 599-5060</p>
          <button className='bg-red-500 text-white p-2 rounded-md'>Book Consultation</button>
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}