import React from 'react'
import handleOpenChatbot from '../utils/ChatbotOpened'
import useMobileDetect from '../hooks/mobileHook'

const ShapeWithText = () => {
  const handleClick = () => {
    handleOpenChatbot()
  }

  const isMobile = useMobileDetect()

  return (
    !isMobile ? (

    
    <svg
      width='350px'
      height='400px'
      viewBox="0 0 118.16461 121.77452"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs
        id="defs1" />
      <g
        id="layer1"
        transform="translate(-16.015269,-28.549514)">
        <g
          id="layer1-4"
          transform="matrix(1.0747428,0,0,1.2509171,-26.731305,-40.375536)"
          //  style="fill:#70f0a6;fill-opacity:1"
          style={{fill: '#70f0a6'}}
          >
          <path
            d="m 119.29753,55.099614 -79.249012,8.08818 -0.274748,66.640056 40.885745,22.61996 1.37953,-11.81852 67.651205,-4.84233 0.0304,-59.439482 z"
            //  style="opacity:1;fill:#70f0a6;fill-opacity:1;stroke-width:0.276217"
            style={{fill: '#70f0a6'}}
            id="path9" />
        </g>
      </g>
      <style type='text/css'>
      </style>
      <text x='46%' y='20%' dominantBaseline='middle' textAnchor='middle' fontFamily='Avenir' fill='black' fontSize='8' >
        <tspan x='47%' fontWeight='bold'>Kwixi's custom AI chatbot</tspan>
        <tspan x='51%' dy='1.3em' fontWeight='light'>learns your business, so it can</tspan>
        <tspan x='49.5%' dy='1.3em'>answer questions, book your</tspan>
        <tspan x='48%' dy='1.3em'>meetings, and capture your</tspan>
        <tspan x='36%' dy='1.3em'>leads automatically.</tspan>
      </text>

      <g className="cursor-pointer" onClick={handleClick}>
        <rect x="33.5%" y="66%" width="34%" height="11%" rx="6" ry="6" fill="black" />
        <text x="51%" y="72%" dominantBaseline="middle" textAnchor="middle" fontFamily="Avenir" fill="#F7EFFF" fontSize="7">
          Let's Talk
        </text>
      </g>
    </svg>
    ) : (
      <MobileShapeWithText />
    )

  


    // <div className=''>
    // <svg
    //   //  width="100.99693mm"
    //   //  height="97.288635mm"
    //   width='240px'
    //   height='240px'
    //    viewBox="0 0 100.99693 97.288635"
    //    version="1.1"
    //    id="svg1"
    //    xmlns="http://www.w3.org/2000/svg"
    //    xmlns:svg="http://www.w3.org/2000/svg">
    //   <defs
    //      id="defs1" />
    //   <g
    //      id="layer1"
    //      transform="translate(-39.65701,-52.780756)"
    //     //  style="fill:#70f0a6;fill-opacity:1"
    //     style={{fill: '#70f0a6'}}
    //      >
    //     <path
    //        d="M 106.41779,52.780758 39.657011,63.337219 v 60.769421 l 29.101066,25.96275 2.567802,-9.98595 69.042811,-5.99137 0.28526,-58.201612 z"
    //       //  style="fill:#70f0a6;fill-opacity:1;stroke-width:0.264583"
    //       style={{fill: '#70f0a6'}}
    //        id="path9" />
    //   </g>
    //   <text x='40%' y='24%' dominantBaseline='middle' textAnchor='middle' fontFamily='Avenir' fill='black' fontSize='6' >
    //     <tspan fontWeight='bold'>Kwixi's custom AI chatbot</tspan>
    //     <tspan x='43%' dy='1.3em'>learns your business, so it can</tspan>
    //     <tspan x='42%' dy='1.3em'>answer questions, book your</tspan>
    //     <tspan x='40%' dy='1.3em'>meetings, and capture your</tspan>
    //     <tspan x='30%' dy='1.3em'>leads automatically.</tspan>
    //   </text>
    //   {/* <button onClick={alert('Button Clicked')}>Let's Chat</button> */}
    // </svg></div>
  )
}

export default ShapeWithText


const MobileShapeWithText = () => {
  const handleClick = () => {
    handleOpenChatbot()
  }
  return (
    <svg
    width='140px'
    // height='100px'
    viewBox="0 0 118.16461 121.77452"
    version="1.1"
    id="svg1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
      id="defs1" />
    <g
      id="layer1"
      transform="translate(-16.015269,-28.549514)">
      <g
        id="layer1-4"
        transform="matrix(1.0747428,0,0,1.2509171,-26.731305,-40.375536)"
        //  style="fill:#70f0a6;fill-opacity:1"
        style={{fill: '#70f0a6'}}
        >
        <path
          d="m 119.29753,55.099614 -79.249012,8.08818 -0.274748,66.640056 40.885745,22.61996 1.37953,-11.81852 67.651205,-4.84233 0.0304,-59.439482 z"
          //  style="opacity:1;fill:#70f0a6;fill-opacity:1;stroke-width:0.276217"
          style={{fill: '#70f0a6'}}
          id="path9" />
      </g>
    </g>
    <style type='text/css'>
    </style>
    <text x='46%' y='20%' dominantBaseline='middle' textAnchor='middle' fontFamily='Avenir' fill='black' fontSize='8' >
      <tspan x='47%' fontWeight='bold'>Kwixi's custom AI chatbot</tspan>
      <tspan x='51%' dy='1.3em' fontWeight='light'>learns your business, so it can</tspan>
      <tspan x='49.5%' dy='1.3em'>answer questions, book your</tspan>
      <tspan x='48%' dy='1.3em'>meetings, and capture your</tspan>
      <tspan x='36%' dy='1.3em'>leads automatically.</tspan>
    </text>

    <g className="cursor-pointer" onClick={handleClick}>
      <rect x="33.5%" y="66%" width="34%" height="11%" rx="6" ry="6" fill="black" />
      <text x="51%" y="72%" dominantBaseline="middle" textAnchor="middle" fontFamily="Avenir" fill="#F7EFFF" fontSize="7">
        Let's Talk
      </text>
    </g>
  </svg>
  )
}