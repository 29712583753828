import React, { useState, useEffect } from 'react'
import socrates from '../assets/images/socrates.jpg'
import jfk from '../assets/images/jfk.jpg'

const FadingContainer = ({ children }) => {
  return (
    <div className='bg-[#F6F6F6] rounded-tr-xl sm:mr-12 flex flex-col sm:gap-16 gap-4 pl-4 pr-10 sm:py-12 py-6 font-medium shadow-xl sm:w-[420px] sm:mt-0 sm:mb-0 w-[300px]'>
      {children}
    </div>
  )
}

const firstContent = (
  <FadingContainer>
    <div className='flex flex-col gap-2'>
      <h3 className='sm:mt-4 sm:text-[20px] text-[14px]'>"This chatbot has more answers than I have questions"</h3>
      <div className='flex items-start ml-4 gap-2'>
        <div className="w-16 h-16">
          <img src={socrates} className='rounded-full' alt='Socrates' />
        </div>
        <div className='sm:text-[26px] text-[18px]'>
          <p>Socrates</p>
          <p className='sm:text-[18px] text-[12px]'>Old-Timer</p>
        </div>
      </div>
    </div>
    <p className='text-[14px] sm:text-[18px]'>
      Kwixi helps businesses like yours turn visitors into customers. Don’t miss out on potential leads—let Kwixi keep your audience engaged and satisfied.
    </p>
  </FadingContainer>
);

const secondContent = (
  <FadingContainer>
    <div className='flex flex-col gap-2'>
      <h3 className='sm:mt-4 sm:text-[20px] text-[14px]'>
        "Ask not what your business can do for you, but what this chatbot can do for your business."
      </h3>
      <div className='flex items-start ml-4 gap-2'>
        <div className="w-16 h-16">
          <img src={jfk} className='rounded-full object-cover w-full h-full' alt='JFK' />
        </div>
        <div className='sm:text-[26px] text-[18px]'>
          <p>John F. Kennedy</p>
          <p className='sm:text-[18px] text-[12px]'>Ex-President</p>
        </div>
      </div>
    </div>
    <p className='text-[14px] sm:text-[18px]'>
      Kwixi elevates your customer service to new heights. Ensure every visitor gets the attention they need with our AI chatbots, turning inquiries into loyal customers.
    </p>
  </FadingContainer>

);


const FadingSwitcher = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fading, setFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFading(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
        setFading(false);
      }, 500); // This should match the CSS transition duration
    }, 7000); // Time between switches

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`transition-opacity duration-500 ${fading ? 'opacity-0' : 'opacity-100'} sm:mt-20`}>
      {currentIndex === 0 ? firstContent : secondContent}
    </div>
  );
}

export default FadingSwitcher