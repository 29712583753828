import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const EmbeddingPage = () => {
  const [embedCode, setEmbedCode] = useState(null)
  const [copied, setCopied] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const clientId = searchParams.get('client_id')

  useEffect(() => {
    if (clientId) {
      axios.get(`${import.meta.env.VITE_API_URL}/embedding_code`, {
        params: {
          client_id: clientId
        }
      })
      .then(response => {
        setEmbedCode(response.data.embed_code)
      })
      .catch(error => {
        console.error('Error getting embedding code:', error)
      })
    }
  }, [clientId])

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(embedCode)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleCopy = () => {
    copyToClipboard()
  }

  const copyBtnClass = `uppercase py-3 w-44  rounded-md tracking-wide ${copied ? 'bg-[#90EFBB] outline outline-1 outline-slate-400' : 'bg-kwixi-purple hover:bg-[#A996E3] outline outline-1 outline-slate-400'}`

const color = '#C1F6D9'
  return (
    <div className='w-full flex justify-center p-8 py-24'>
      {embedCode && (
        <div className='flex flex-col gap-8'>
          <div className='flex'>
            <button onClick={handleCopy} className={copyBtnClass}>
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>

          <div className='px-4 py-3 bg-[#F2FDF7] outline-3 outline outline-slate-300 rounded-xl'>
            <div className='w-[800px] text-left line-clamp-3 text-ellipsis overflow-hidden'>
              {embedCode}
            </div>
          </div>
          
          
        </div>
      )}
    </div>
  )
}

export default EmbeddingPage