import React from 'react'
import { motion } from 'framer-motion'

export const AnimatedImage = ({ src, alt, direction, styles }) => {
  const getInitial = () => {
    switch (direction) {
      case 'left':
        return { opacity: 0, x: '-100%', y: 0 };
      case 'right':
        return { opacity: 0, x: '100%', y: 0 };
      case 'top':
        return { opacity: 0, x: 0, y: '-100%' };
      case 'bottom':
        return { opacity: 0, x: 0, y: '100%' };
      default:
        return { opacity: 0, x: 0, y: 0 };
    }
  };
  return (
    <motion.img 
      key={src}
      src={src}
      initial={getInitial()}
      whileInView={{opacity: 1,
        x: 0,
        y: 0,
        }}
      transition={{ duration: 1 }}
      alt={alt}
      className={styles}
    />
  )
}

export const AnimatedDiv = ({ direction, styles, children }) => {
  const getInitial = () => {
    switch (direction) {
      case 'left':
        return { opacity: 0, x: '-100%', y: 0 };
      case 'right':
        return { opacity: 0, x: '100%', y: 0 };
      case 'top':
        return { opacity: 0, x: 0, y: '-100%' };
      case 'bottom':
        return { opacity: 0, x: 0, y: '100%' };
      default:
        return { opacity: 0, x: 0, y: 0 };
    }
  };
  return (
    <motion.div 
      className={styles}
      initial={getInitial()}
      whileInView={{opacity: 1,
        x: 0,
        y: 0,
        }}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div> 


  )
}