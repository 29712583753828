import React, { useState, useEffect } from 'react'
import ShapeWithText from '../components/ShapeWithText'
import { SupportCard, SchedulingCard, SalesCard, BusinessSavvyCard } from '../components/ProductCards'
import snowy from '../assets/images/snowy.jpeg'
import dashboard from '../assets/images/dashboard.png'
import ProductPage from './ProductPage'
import { AnimatedDiv } from '../components/AnimatedElement'
import LoadingSpinner from '../components/LoadingSpinner'
import FadingSwitcher from '../components/FadingSwitcher'

const imageUrls = [
  '../assets/images/snowy.jpeg',
  '../assets/images/socrates.jpg',
  '../assets/images/dashboard.png',
  '../assets/images/woman-with-laptop-documents.jpg',
  '../assets/images/man-working.jpg',
  '../assets/images/speed-gauge.png',
  '../assets/images/jfk.jpg'
]

const HomePage = () => {
  const [loading, setLoading] = useState(true)
  const [loadedCount, setLoadedCount] = useState(0)
  
  useEffect(() => {
    const handleImageLoad = () => {
      setLoadedCount((prev) => prev + 1);
    };

    imageUrls.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad; // handle error similarly to avoid blocking the spinner removal
    });
  }, []);

  useEffect(() => {
    if (loadedCount === imageUrls.length) {
      setLoading(false);
    }
  }, [loadedCount]);

  if (loading) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className='flex flex-col w-full items-center mb-16'>
      <section className='w-full'>
        <div className='w-full bg-[#d0f7e2] flex flex-col items-center'>
          <div className='sm:max-width font-black text-left leading-tight my-10 uppercase'>
            <h1 className='tracking-widest sm:text-[56px] text-[32px]'>ignite growth</h1>
            <h1 className='tracking-wider sm:text-[42px] text-[20px]'>& build connections with</h1>
            <h1 className='tracking-widest sm:text-[60px] text-[28px]'>conversational ai</h1>
          </div>

          <div className='flex flex-row'>
            <ShapeWithText />
            <AnimatedDiv direction='top' styles='sm:w-[600px] w-[220px] ml-[-2px]'>
            <img src={dashboard} className='rounded-xl' />
            </AnimatedDiv>

          </div>

        </div>

      </section>
      <section id='features' className='sm:mb-16 mb-0 max-width'>
        <h1 className='font-medium sm:text-[40px] text-[32px] tracking-wide sm:mb-8 mb-4'>Features</h1>
        <div className='grid md:grid-cols-4   feature-cards:grid-cols-1 sm:gap-4 gap-2 sm:px-0 px-2'>
          <SupportCard />
          <SchedulingCard />
          <SalesCard />
          <BusinessSavvyCard />
        </div>
      </section>
      
      <ProductPage />

      <section className='w-full sm:mt-16 sm:h-auto sm:mb-12 mb-0 h-[500px]'>
        <div className='sm:relative flex justify-center sm:h-auto '>
          <img className='sm:w-screen sm:h-[400px] h-[180px] rounded-tr-xl sm:object-fill object-none' src={snowy} />
          <div className='max-width flex sm:flex-row flex-col sm:justify-around items-center absolute sm:gap-64 text-left w-full sm:h-[400px] '>
           <h2 className='text-left text-[#FEFDFB] font-semibold sm:text-[56px] text-[32px] sm:mb-0 py-4'>Why<br/>companies<br/>like Kwixi?</h2>
            {/* <div className='bg-[#F6F6F6] rounded-tr-xl sm:mr-12 flex flex-col sm:gap-12 gap-4 pl-4 pr-12 sm:py-8 py-6 font-medium shadow-xl sm:w-[420px] sm:mt-16 sm:mb-0 w-[300px]'>
              <div className='flex flex-col gap-2'>
                <h3 className='sm:mt-8 sm:text-[20px] text-[14px]'>"This chatbot has more answers than I have questions"</h3>
                <div className='flex items-start ml-4 gap-2'>
                  <div className="w-16 h-16">
                      <img src={socrates} className='rounded-full' />
                  </div>
                  <div className='sm:text-[26px] text-[18px]'>
                    <p>Socrates</p>
                    <p className='sm:text-[18px] text-[12px]'>Old-Timer</p>
                  </div>
                
                </div>
              </div>

              <p className='text-[14px] sm:text-[18px]'>
              Kwixi helps businesses like yours turn visitors into customers. Don’t miss out on potential leads—let Kwixi keep your audience engaged and satisfied.
              </p>

            </div> */}

            <FadingSwitcher />
          </div>
            
        </div>
        
      </section>     
    </div>
  )
}

export default HomePage