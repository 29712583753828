import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import './App.css'
import Navbar from './layout/Navbar'
import PlansPage from './pages/PlansPage'
import LearnPage from './pages/LearnPage'
import Footer from './layout/Footer'
import ContactPage from './pages/ContactPage'
import HomePage from './pages/HomePage'
import SignIn from './pages/SignIn'
import DemoPage from './pages/DemoPage'
import EmbeddingPage from './pages/EmbeddingPage'


function App() {
  const location = useLocation()
  const isDemoPage = location.pathname === '/demo'

  useEffect(() => {
    const kwixiChatbot = document.getElementById('chatbot-container')
    const demoChatbot = document.getElementById('demo-chatbot-container');

    if (isDemoPage) {
      if (kwixiChatbot) {
        kwixiChatbot.style.display = 'none'
      }
    } else {
      kwixiChatbot.style.display = 'block'
    }
  }, [isDemoPage, location.pathname])

  return (
    <div className='App font-avenir min-h-screen text-content bg-[#FFFFFF] flex flex-col'>
      <Navbar />
      <div className='main-content flex flex-col flex-grow h-full'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          {/* <Route path='/product' element={<ProductPage />} /> */}
          <Route path='/plans' element={<PlansPage />} />
          <Route path='/learn' element={<LearnPage />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/demo' element={<DemoPage />} />
          <Route path='/embedding' element={<EmbeddingPage />} />  
        </Routes>
      </div>
      
      {!isDemoPage && <Footer />}
      {/* <Footer /> */}
    </div>
  )
}

export default App
